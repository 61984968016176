import React from 'react';
import { Box, Typography, Link, IconButton, Grid } from '@mui/material';
import InstagramIcon from '@mui/icons-material/Instagram';
import TikTokIcon from '@mui/icons-material/LinkedIn';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import AppleIcon from '@mui/icons-material/Apple';
import AndroidIcon from '@mui/icons-material/Android';
import logo from '../asstes/images/logo_white.png'
import apple from '../asstes/images/apple.png'
import google from '../asstes/images/google.png'
import Colors from '../asstes/color';

const Footer = () => {
    return (
        <Box
            sx={{
                backgroundColor: Colors.textSecondary,
                color: 'white',
                padding: '20px',
                // mt: 4,
            }}
        >
            <Grid container spacing={2} justifyContent="center">
                {/* Logo and Social Paws Text */}
                <Grid item xs={12} sm={3} textAlign="center">
                    <Box display="flex" flexDirection="column" alignItems="center">
                        <Box
                            component="img"
                            src={logo}
                            alt="Social Paws Logo"
                            sx={{ width: 50, height: 50 }}
                        />
                        <Typography variant="h6" sx={{
                            flexGrow: 1,
                            // mr: 2, ml: 3,
                            display: { xs: 'flex', md: 'flex' },
                            fontFamily: 'MyCustomFont1',
                            fontWeight: 600,
                            fontSize: '25px',
                            // letterSpacing: '.3rem',
                            color: Colors.white,
                            textDecoration: 'none', mt: 1
                        }}>Social Paws</Typography>
                    </Box>
                </Grid>

                {/* Service Links */}
                <Grid item xs={12} sm={3} >
                    <Typography variant="h6" sx={{
                        flexGrow: 1,
                        // mr: 2, ml: 3,
                        display: { xs: 'flex', md: 'flex' },
                        fontFamily: 'MyCustomFont1',
                        fontWeight: 600,
                        fontSize: '25px',
                        // letterSpacing: '.3rem',
                        color: Colors.white,
                        textDecoration: 'none',
                    }}>Service</Typography>

                    <Box mt={1}>
                        <Link href="/faqs" color="inherit" underline="hover">
                            FAQ
                        </Link>
                    </Box>
                    <Box mt={1}>
                        <Link href="/how_its_work" color="inherit" underline="hover">
                            How it Works
                        </Link>
                    </Box>
                    <Box mt={1}>
                        <Link href="/events" color="inherit" underline="hover">
                            Events
                        </Link>
                    </Box>
                </Grid>

                {/* Social Paws Links */}
                <Grid item xs={12} sm={3} >
                    <Typography variant="h6" sx={{
                        flexGrow: 1,
                        // mr: 2, ml: 3,
                        display: { xs: 'flex', md: 'flex' },
                        fontFamily: 'MyCustomFont1',
                        fontWeight: 600,
                        fontSize: '25px',
                        // letterSpacing: '.3rem',
                        color: Colors.white,
                        textDecoration: 'none',
                    }}>Social Paws</Typography>

                    <Box mt={1}>
                        <Link href="/origin_story" color="inherit" underline="hover">
                            Origin Story
                        </Link>
                    </Box>
                    <Box mt={1}>
                        <Link href="/team" color="inherit" underline="hover">
                            Meet the Team
                        </Link>
                    </Box>
                    <Box mt={1}>
                        <Link href="/" color="inherit" underline="hover">
                            Home
                        </Link>
                    </Box>
                    <Box mt={1}>
                        <Link href="/blog" color="inherit" underline="hover">
                            Blog
                        </Link>
                    </Box>
                </Grid>

                {/* Follow Us and App Download */}
                <Grid item xs={12} sm={3} >
                    <Typography variant="h6" sx={{
                        // flexGrow: 1,
                        // mr: 2, ml: 3,
                        // display: { xs: 'flex', md: 'flex' },
                        fontFamily: 'MyCustomFont1',
                        fontWeight: 600,
                        fontSize: '25px',
                        // letterSpacing: '.3rem',
                        color: Colors.white,
                        textDecoration: 'none',
                    }}>Follows us</Typography>
                    <Box>
                        <IconButton color="inherit" href="#">
                            <InstagramIcon />
                        </IconButton>
                        <IconButton color="inherit" href="#">
                            <TikTokIcon />
                        </IconButton>
                        <IconButton color="inherit" href="#">
                            <InstagramIcon />
                        </IconButton>
                    </Box>

                    <Grid container spacing={2} >
                        <Grid item>
                            <a href="https://apps.apple.com/us/app/social-paws/id6443444135" target="_blank" rel="noopener noreferrer">
                                <img src={apple} alt="App Store" width="100" />
                            </a>
                        </Grid>
                        <Grid item>
                            <a href="https://play.google.com/store/apps/details?id=com.bytes.socialpaws&pli=1" target="_blank" rel="noopener noreferrer">
                                <img src={google} alt="Google Play" width="100" />
                            </a>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
};

export default Footer;
