import React, { useState, useEffect } from "react";
import { Grid, Typography, Box, TextField } from "@mui/material";
import NavBar from "../../components/NavBar";
import Footer from "../../components/Footer";
import Colors from "../../asstes/color";

const Calendar = () => {
    const [events, setEvents] = useState([
        { date: 9, name: "Doggy Paddle" },
        { date: 20, name: "Dog Run" },
    ]);
    const [currentDate, setCurrentDate] = useState(new Date());
    const [days, setDays] = useState([]);

    useEffect(() => {
        const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
        const lastDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
        const daysInMonth = Array.from({ length: lastDayOfMonth.getDate() }, (_, i) => i + 1);
        setDays(daysInMonth);
    }, [currentDate]);

    const renderDays = () => {
        const daysInWeek = 7;
        const totalDays = days.length;
        const additionalBoxes = (daysInWeek - (totalDays % daysInWeek)) % daysInWeek;
        return (
            <>
                {days.map((day) => {
                    const event = events.find((e) => e.date === day);
                    return (
                        <Grid
                            item
                            xs={4} sm={2} md={1.5} lg={1.3} xl={1}
                            key={day}
                            style={{ height: "120px", cursor: 'pointer', border: "1px solid #2BB1E6", borderRadius: '10px', backgroundColor: event ? "rgba(43, 177, 230, 0.2)" : "transparent" }}
                        >
                            <Typography variant="body1" sx={{ fontFamily: 'MyCustomFont1', }}>
                                {day}
                            </Typography>
                            {event && (
                                <Typography variant="body2" align="center" color="primary" sx={{
                                    fontFamily: 'MyCustomFont1',
                                    fontWeight: 400,
                                    fontSize: { xs: 12, sm: 15 },
                                    color: Colors.black,
                                    mt: 2
                                }}>
                                    {event.name}
                                </Typography>
                            )}
                        </Grid>
                    );
                })}

                {/* {[...Array(additionalBoxes)].map((_, index) => (
                    <Grid
                        item
                        xs={4} sm={2} md={1.5} lg={1.3} xl={1}
                        key={`empty-${index}`}
                        sx={{
                            height: "120px",
                            border: "1px solid #2BB1E6",
                            borderRadius: '10px',
                        }}
                    />
                ))} */}
            </>
        )
    };

    return (
        <Box sx={{ padding: { xs: 1, md: 0 } }}>
            <NavBar screen={"event"} />
            <Typography variant="h4" align="center" color="primary" gutterBottom sx={{
                fontFamily: 'MyCustomFont',
                fontWeight: 700,
                fontSize: { xs: 24, sm: 30, md: 35 },
                color: Colors.textSecondary,
                textDecoration: 'none',
                mt: 2
            }}>
                Events
            </Typography>
            <Box sx={{
                backgroundColor: Colors.green,
                padding: { xs: 1, sm: 2 },
                borderRadius: '10px',
                mr: { xs: 2, sm: 5, md: 13 },
                ml: { xs: 2, sm: 5, md: 9 },
                // textAlign: 'center'
            }}>
                <Typography variant="h6" sx={{
                    fontFamily: 'MyCustomFont1',
                    fontWeight: 600,
                    fontSize: { xs: 16, sm: 20, md: 22 },
                    color: Colors.white,
                }}>
                    {currentDate.toLocaleString("default", { month: "long" })}
                </Typography>
            </Box>
            <Grid container spacing={1} sx={{ maxWidth: "1200px", margin: "0 auto", mb: 3 }}>
                {renderDays()}
            </Grid>
            <Box sx={{ backgroundColor: Colors.textSecondary, padding: 1 }}>
                <Box sx={{
                    paddingLeft: { xs: 2, sm: 8 },
                    maxWidth: '600px',
                    // mx: "auto",
                    color: Colors.white
                }}>
                    <Typography variant="h5" sx={{ color: Colors.white, mb: 1 }}>Sign Up</Typography>

                    {["Name", "Phone", "Email"].map((label, index) => (
                        <Box key={index} sx={{ mb: 1 }}>
                            <Typography variant="subtitle1" sx={{ color: Colors.white, mb: 0 }}>
                                {label}
                            </Typography>
                            <TextField
                                fullWidth
                                margin="dense"
                                InputProps={{
                                    style: {
                                        borderRadius: '30px',
                                        backgroundColor: Colors.textSecondary,
                                    }
                                }}
                                sx={{
                                    "& .MuiOutlinedInput-root": {
                                        height: 35,
                                        "& input": {
                                            padding: "24px 20px",
                                            textAlign: "center",
                                        },
                                        "& fieldset": {
                                            borderColor: Colors.white,
                                        },
                                        "&:hover fieldset, &.Mui-focused fieldset": {
                                            borderColor: Colors.white,
                                        }
                                    },
                                    "& .MuiInputLabel-root, & .MuiInputLabel-root.Mui-focused": {
                                        color: Colors.white,
                                    },
                                    "& .MuiInputLabel-root.Mui-error": {
                                        color: "red",
                                    },
                                }}
                            />
                        </Box>
                    ))}
                </Box>
            </Box>


            {/* footer */}
            <Footer />
        </Box>
    );
};

export default Calendar;
