import React from 'react';
import { Grid2, Box } from '@mui/material';
import insta1 from '../../asstes/images/insta1.png'
import insta2 from '../../asstes/images/insta2.png'
import { makeStyles } from '@mui/styles';


const images = [
    insta1,
    insta2,
    insta2,
    insta1,
    insta2,
    insta2, insta1,
];

const useStyles = makeStyles({
    background: {
        width: '100%',
        // height: '30vh',//100
        display: 'flex',
        overflow: 'hidden',
    },

});


const InstagramPost = () => {
    const classes = useStyles();
    return (
        <Box sx={{
            flexGrow: 1, padding: 0, justifyContent: 'center',
            alignItems: 'center', display: 'flex',
        }} className={classes.background}>
            <Box sx={{ display: 'flex' }} spacing={0}>

                <Grid2 item xs={12}>
                    <Box
                        component="img"
                        src={images[0]}
                        alt="Dog 1"
                        sx={{
                            width: '100%',
                            height: 'auto',
                            display: 'block',
                        }}
                    />
                </Grid2>

                <Grid2 item xs={6}>
                    <Box
                        component="img"
                        src={images[1]}
                        alt="Dog 2"
                        sx={{
                            width: '100%',
                            height: 'auto',
                            display: 'block',
                            //marginBottom: 2, // Optional spacing between images
                        }}
                    />
                    <Box
                        component="img"
                        src={images[2]}
                        alt="Dog 3"
                        sx={{
                            width: '100%',
                            height: 'auto',
                            display: 'block',
                        }}
                    />
                </Grid2>


                <Grid2 item xs={12}>
                    <Box
                        component="img"
                        src={images[3]}
                        alt="Dog 4"
                        sx={{
                            width: '100%',
                            height: 'auto',
                            display: 'block',
                        }}
                    />
                </Grid2>


                <Grid2 item xs={6}>
                    <Box
                        component="img"
                        src={images[4]}
                        alt="Dog 2"
                        sx={{
                            width: '100%',
                            height: 'auto',
                            display: 'block',
                            //marginBottom: 2, // Optional spacing between images
                        }}
                    />
                    <Box
                        component="img"
                        src={images[5]}
                        alt="Dog 3"
                        sx={{
                            width: '100%',
                            height: 'auto',
                            display: 'block',
                        }}
                    />
                </Grid2>

            </Box>
        </Box>
    );
};

export default InstagramPost;
