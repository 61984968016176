import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import SP_Pattern from '../../asstes/images/SP_Pattern.png' // Import your local image
import Colors from '../../asstes/color';

const useStyles = makeStyles({
    background: {
        width: '100%',
        height: '30vh',//100
        display: 'flex',
        // marginLeft: 30,
        justifyContent: 'center',
        alignItems: 'center',
        overflow: 'hidden',
        backgroundColor: Colors.white
    },
    scrollingText: {
        display: 'inline-block',
        whiteSpace: 'nowrap',
        opacity: 1,
        fontSize: '2rem',
        color: '#000',
        transition: 'opacity 1s ease-in-out', 
    },
    hiddenText: {
        opacity: 0, 
    },
});

function FollowInstaText() {
    const classes = useStyles();

    return (
        <Box className={classes.background}>
            <Box >
                <Typography
                    sx={{
                        flexGrow: 1,
                        display: { xs: 'flex', md: 'flex' },
                        fontFamily: 'MyCustomFont1',
                        fontWeight: 600,
                        fontSize: { xs: '24px', sm: '30px', md: '50px' },
                        color: Colors.textSecondary,
                        textDecoration: 'none',
                        mt: { xs: 2, sm: 3, md: 4 },
                        textAlign: { xs: 'center', md: 'left' }
                    }}
                >
                    Follow Us On Instagram @socialpawsapp
                </Typography>
            </Box>
        </Box>
    );
}

export default FollowInstaText;
