import React from 'react';

const PawPledge = () => {
    const styles = {
        container: {
            //   maxWidth: '800px',
            margin: 'auto',
            padding: '20px',
            fontFamily: 'Arial, sans-serif',
            color: '#333',
        },
        title: {
            fontSize: '20px',
            //   textAlign: 'center',
            marginBottom: '10px',
        },
        sectionTitle: {
            fontSize: '18px',
            marginTop: '20px',
        },
        paragraph: {
            fontSize: '14px',
            lineHeight: '1.6',
            margin: '10px 0',
        },
        '@media (max-width: 768px)': {
            container: {
                padding: '15px',
            },
            title: {
                fontSize: '18px',
            },
            sectionTitle: {
                fontSize: '16px',
            },
            paragraph: {
                fontSize: '14px',
            },
        },
        '@media (max-width: 480px)': {
            container: {
                padding: '10px',
            },
            title: {
                fontSize: '18px',
            },
            sectionTitle: {
                fontSize: '12px',
            },
            paragraph: {
                fontSize: '12px',
            },
        },
    };

    return (
        <div style={styles.container}>
            <p style={styles.paragraph}>
                I pledge that:
            </p>
            <p><strong>My dog is a safe dog</strong></p>
            <p>My dog is up to date on all required and recommended vaccinations</p>
            <p>My dog has a safe temperament and is not a dangerous dog</p>

            <h2 style={styles.sectionTitle}>I will share with Team Social as much information as possible regarding:</h2>
            <p style={styles.paragraph}>
                my dog’s temperament
            </p>
            <p style={styles.paragraph}>
                my dog’s level of behavior training
            </p>
            <p style={styles.paragraph}>
                my dog’s level of house training
            </p>
            <p style={styles.paragraph}>
                anything I believe is important to know when caring for my dog
            </p>

            <h2 style={styles.sectionTitle}>I will ensure that my dog has some form of identification</h2>

        </div>
    );
};

export default PawPledge;
