import React from 'react';

const SocialPledge = () => {
    const styles = {
        container: {
            //   maxWidth: '800px',
            margin: 'auto',
            padding: '20px',
            fontFamily: 'Arial, sans-serif',
            color: '#333',
        },
        title: {
            fontSize: '20px',
            //   textAlign: 'center',
            marginBottom: '10px',
        },
        sectionTitle: {
            fontSize: '18px',
            marginTop: '20px',
        },
        paragraph: {
            fontSize: '14px',
            lineHeight: '1.6',
            margin: '10px 0',
        },
        '@media (max-width: 768px)': {
            container: {
                padding: '15px',
            },
            title: {
                fontSize: '18px',
            },
            sectionTitle: {
                fontSize: '16px',
            },
            paragraph: {
                fontSize: '14px',
            },
        },
        '@media (max-width: 480px)': {
            container: {
                padding: '10px',
            },
            title: {
                fontSize: '18px',
            },
            sectionTitle: {
                fontSize: '12px',
            },
            paragraph: {
                fontSize: '12px',
            },
        },
    };

    return (
        <div style={styles.container}>
            <p style={styles.paragraph}>
                I pledge to:
            </p>
            <p><strong>Properly control the dog’s environment </strong></p>
            <p>Follow Team Paws’ leashing guidelines for their dog</p>
            <p>Provide fencing where appropriate</p>
            <p>Protect the dog from real and potential hazards</p>

            <h2 style={styles.sectionTitle}>Pick up and properly dispose of the dog’s waste in all public areas </h2>

            <h2 style={styles.sectionTitle}>Care for the dog as if it is my own and return the dog in the same condition in which it was shared to me</h2>

        </div>
    );
};

export default SocialPledge;
