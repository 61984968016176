import React from 'react';
import { Box, Typography, Avatar, Paper, useMediaQuery } from '@mui/material';
import Carousel from 'react-material-ui-carousel'
import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material/styles';
import Colors from '../../asstes/color';
import comment_img1 from '../../asstes/images/comment_img1.png'
import comment_img2 from '../../asstes/images/comment_img2.png'

const useStyles = makeStyles((theme) => ({
    carouselWrapper: {
        backgroundColor: Colors.green,
        padding: theme.spacing(3),
        display: 'flex', // Enable flexbox
        justifyContent: 'center', // Center horizontally
        alignItems: 'center',
        // height:'30vh'
        // borderRadius: theme.shape.borderRadius,
    },
    testimonialCard: {
        display: 'flex',
        alignItems: 'center',
        boxShadow: 'none',         // Remove shadow
        //backgroundColor: 'transparent', // Optional: Makes background transparent
        border: 'none',
        padding: theme.spacing(3),
        backgroundColor: Colors.white, // Set background for each card to blue
        // borderRadius: theme.shape.borderRadius,
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            padding: theme.spacing(2),
            textAlign: 'center',
        },
    },
    avatar: {
        width: '100px !important',
        height: '100px !important',
        marginRight: theme.spacing(2),
        [theme.breakpoints.down('sm')]: {
            margin: theme.spacing(1, 0),
        },
    },
    badge: {
        // backgroundColor: Colors.white,
        color: 'white',
        padding: '5px 10px',
        borderRadius: 15,
        fontSize: 12,
        fontWeight: 'bold',
        marginRight: theme.spacing(1),
        [theme.breakpoints.down('sm')]: {
            marginRight: 0,
            marginBottom: theme.spacing(1),
        },
    },
    testimonialText: {
        color: Colors.white,
        [theme.breakpoints.down('sm')]: {
            fontSize: '0.9rem',
        },
    },
    // testimonialText: {
    //     color: '#ffffff',
    //     marginTop: theme.spacing(1),
    //     textAlign: 'left', // Ensures text alignment to the left
    // },

    name: {
        color: '#ffffff',
        fontWeight: 'bold',
        marginLeft: theme.spacing(1), // Add margin to shift the name slightly left
    },
    // team: {
    //     color: '#e0f7fa',
    //     marginLeft: theme.spacing(1), // Align the team to the left
    // },
    team: {
        color: '#e0f7fa',
        textAlign: 'right', // Align team text to the right
        flexShrink: 0, // Prevent the team name from shrinking
    },
    nameTeamContainer: {
        display: 'flex',
        // justifyContent: 'space-between', // Space between name and team
        width: '100%', // Take full width
        marginBottom: theme.spacing(1), // Space below this container
    },
    carouselContainer: {
        width: '70%', // Carousel width
    },
}));


function TestimonialCarousel() {
    const classes = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const testimonials = [
        {
            name: 'Stephanie',
            team: 'Team Paws',
            text: "Since moving to NYC, I'd been missing that presence of a dog in my life. Finally, your pack walk with Asha gave me the boost to say – 'OK, I'm ready, let's do this!' Two weeks later, we brought Winnie home.",
            imageUrl: comment_img2, // replace with actual image URL
            // badge: 'Adopted Winnie',
        },
        {
            name: 'Angela',
            team: 'Team Social',
            text: "SI love dogs, but am not in a place to have my own, so Social Paws is a great opportunity to enjoy their company occasionally [and help their owners at the same time!] ",
            imageUrl: comment_img1, // replace with actual image URL
            // badge: 'Adopted Winnie',
        },
        {
            name: 'Stephanie',
            team: 'Team Paws',
            text: "Social Paws is amazing. I saved $800 on dog-boarding costs while I was in Mexico w/ my son & got to know that Darci was being spoiled & loving it!",
            imageUrl: comment_img1, // replace with actual image URL
            // badge: 'Adopted Winnie',
        },
        // Add more testimonials here as needed
    ];

    return (
        <Box className={classes.carouselWrapper}>
            <Box className={classes.carouselContainer}>
                <Carousel autoPlay={false} navButtonsAlwaysInvisible indicatorIconButtonProps={{
                    style: {
                        color: "#B0BEC5",    // inactive dot color
                    }
                }}
                    activeIndicatorIconButtonProps={{
                        style: {
                            color: Colors.white   // active dot color
                        }
                    }}>
                    {testimonials.map((testimonial, index) => (
                        <Paper key={index} className={classes.testimonialCard} elevation={0} style={{ backgroundColor: Colors.green }}>

                            <Avatar src={testimonial.imageUrl} className={classes.avatar} />

                            <Box textAlign="left">
                                <Box className={classes.nameTeamContainer}>
                                    <Typography variant="h6" className={classes.name} sx={{
                                        // flexGrow: 1,
                                        // mr: 2, ml: 3,
                                        display: { xs: 'flex', md: 'flex' },
                                        fontFamily: 'MyCustomFont1',
                                        fontWeight: 600,
                                        fontSize: '30px',
                                        // letterSpacing: '.3rem',
                                        color: Colors.white,
                                        textDecoration: 'none', mt: 0
                                    }}>
                                        {testimonial.name}
                                    </Typography>

                                    <Typography variant="body2" className={classes.team} sx={{
                                        // flexGrow: 1,
                                        // mr: 2, ml: 3,
                                        display: { xs: 'flex', md: 'flex' },
                                        fontFamily: 'MyCustomFont1',
                                        fontWeight: 400,
                                        fontSize: '10px',
                                        // letterSpacing: '.3rem',
                                        color: Colors.white,
                                        textDecoration: 'none', mt: 0
                                    }}>
                                        {testimonial.team}
                                    </Typography>

                                </Box>
                                <Typography variant="body2" className={classes.testimonialText} sx={{
                                    // flexGrow: 1,
                                    // mr: 2, ml: 3,
                                    display: { xs: 'flex', md: 'flex' },
                                    fontFamily: 'MyCustomFont1',
                                    fontWeight: 400,
                                    fontSize: '20px',
                                    // letterSpacing: '.3rem',
                                    color: Colors.white,
                                    textDecoration: 'none', mt: 0
                                }}>
                                    {testimonial.text}
                                </Typography>
                            </Box>

                        </Paper>
                    ))}
                </Carousel>
            </Box>
        </Box>
    );
}

export default TestimonialCarousel;
