import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import SP_Pattern from '../../asstes/images/SP_Pattern.png' // Import your local image
import Colors from '../../asstes/color';
import bg_wp from '../../asstes/images/bg_wp.png'

const useStyles = makeStyles((theme) => ({
    background: {
        backgroundImage: `url(${bg_wp})`, // Use the imported image here
        backgroundSize: 'contain',
        [theme.breakpoints.up('lg')]: {
            // Styles for screens 1200px and above
            backgroundSize: 'cover',
        },
        width: '100%',
        height: '30vh',//100
        display: 'flex',
        overflow: 'hidden',
        zIndex: 1,
        backgroundRepeat: 'no-repeat',
    },
    scrollingText: {
        display: 'inline-block',
        whiteSpace: 'nowrap',
        opacity: 1,
        fontSize: '2rem',
        color: '#000',
        transition: 'opacity 1s ease-in-out', 
    },
    hiddenText: {
        opacity: 0, 
    },
}));

function TwoTeamsText() {
    const classes = useStyles();


    return (
        <Box className={classes.background}>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: { xs: 1, md: 10 } }}>
                <Typography
                    sx={{
                        fontFamily: 'MyCustomFont1',
                        fontWeight: 600,
                        fontSize: { xs: '24px', md: '35px' },
                        color: Colors.white,
                        // textAlign: 'center',
                        mb: 2,
                        mr: 2, ml: { xs: 2, md: 6 },
                    }}
                >
                    There are two Teams!
                </Typography>

                {/* <Box sx={{ display: 'flex', gap: 2, mt: 2, zIndex: 1 }}> */}
                <Box sx={{ display: 'flex', position: 'absolute', zIndex: 10, gap: 2, mt: { xs: 5, md: 12 }, mr: 2, ml: 0, }}>
                    <Typography
                        sx={{
                            fontFamily: 'MyCustomFont1',
                            fontWeight: 600,
                            fontSize: { xs: '10px', md: '20px' },
                            color: Colors.textSecondary,
                            padding: '8px 16px',
                            borderColor: Colors.secondary, // Define your secondary color in Colors
                            borderRadius: { xs: '10px', md: '28px' },
                            bgcolor: Colors.white
                        }}
                    >
                        Team Social
                    </Typography>
                    <Typography
                        sx={{
                            fontFamily: 'MyCustomFont1',
                            fontWeight: 600,
                            fontSize: { xs: '10px', md: '20px' },
                            color: Colors.textSecondary,
                            padding: '8px 16px',
                            borderColor: Colors.secondary, // Define your secondary color in Colors
                            borderRadius: { xs: '10px', md: '28px' },
                            bgcolor: Colors.white
                        }}
                    >
                        Team Paws
                    </Typography>
                </Box>
            </Box>
        </Box>
    );
}

export default TwoTeamsText;
