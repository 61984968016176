import React from 'react';
import { Container, Box, Typography } from '@mui/material';
import NavBar from '../../components/NavBar';
import Footer from '../../components/Footer';
import ScrollableView from '../../screens/Originstory/ScrollableView'
import Section from './Section';
import img1 from '../../asstes/images/origin_story/img1.png';
import img2 from '../../asstes/images/origin_story/img2.png';
import Colors from '../../asstes/color';

function OriginStory() {
    return (
        <Box sx={{ padding: { xs: 1, md: 0 } }}>
            <NavBar screen={"event"} />
            <Box sx={{ mt: 3, mb: 4, textAlign: 'center' }}>
                <Typography
                    variant="h2"
                    sx={{
                        fontFamily: 'MyCustomFont',
                        // left: '50%',
                        fontWeight: 700,
                        color: Colors.textSecondary,
                        fontSize: { xs: '1.8rem', md: '3rem' },
                        whiteSpace: 'nowrap',
                        textAlign: 'center',
                        // zIndex: 1,
                    }}
                >
                    Origin Story
                </Typography>
            </Box>

            <Section
                title="How it started"
                description="Inspired by her personal life-changing dog-sharing experience, Social Paws’ founder, Jeanette, wanted to create a way for everyone to access what she had with dog-sharing: help with her dog, connection with her neighbors, and saving lots of money!
Her eyes were opened to the need for affordable pet care for dog-owners and accessible pet therapy for non-owners. The more she worked on Social Paws, the more convinced she became that neighbors must be empowered to be each other’s solutions!
Her idea slowly and steadily evolved into what Social Paws is today: an app that makes dog-sharing a possibility for everyone"
                image={img1}
            />
            <Section
                title="How it’s going..."
                description="While Social Paws is primarily a dog-sharing app, it also doubles as a social app. It exists to unite communities and like-minded individuals around a common interest and goal. And that goal is affordable and loving pet-care for those who need it AND more time spent with dogs for those who don’t have one. Dog-sharing works, plain and simple, and Social Paws facilitates the process so you can directly gain access to a community that wants to share dogs, within your very own neighborhood. There are other dog care apps out there, but Social Paws is different in the following ways:"
                image={img2}
                reverse
            />
            <ScrollableView/>
            <Footer />
        </Box>
    ); 
}

export default OriginStory;


