// color.js

const Colors = {
    // Primary colors
    primary: "#3498db",     // Blue
    primaryLight: "#5dade2",
    primaryDark: "#2e86c1",

    // Secondary colors
    secondary: "#e74c3c",   // Red
    secondaryLight: "#ec7063",
    secondaryDark: "#c0392b",

    // Neutral colors
    white: "#ffffff",
    black: "#000000",
    lightGray: "#dcdcdc",
    mediumGray: "#a9a9a9",
    darkGray: "#696969",

    // Additional colors
    success: "#2ecc71",     // Green
    warning: "#f39c12",     // Yellow
    danger: "#e74c3c",      // Red (same as secondary, but you can adjust if needed)
    info: "#3498db",        // Blue (same as primary, but you can adjust if needed)

    // Backgrounds
    background: "#D3FFFA",  // Light background
    backgroundDark: "#1c2833",

    //green
    green:'#5CC2B2',

    // Text colors
    textPrimary: "#009BCE", // Dark text
    textSecondary: "#2BB1E6",
    textLight: "#f4f4f4",

    // Transparent colors
    transparent: "transparent",
    overlay: "rgba(0, 0, 0, 0.5)",

    seperatorColor:'#1860B4'
};

export default Colors;
