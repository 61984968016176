import React from 'react';
import { Box, Button, Fab, Typography, } from '@mui/material';
import Grid from '@mui/material/Grid2';
import bg from '../../asstes/images/bg.png'
import Colors from '../../asstes/color';
import apple from '../../asstes/images/apple.png'
import google from '../../asstes/images/google.png'
import MessageIcon from '../../asstes/images/msg.png'

const LoveOfDogs = () => {
    return (
        <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} sx={{ bgcolor: Colors.background, zIndex: 2, position: 'relative', p: { xs: 2, sm: 4, md: 6 } }} overflow="hidden">
            <Grid
                container
                spacing={1}
                alignItems="center"
                justifyContent="center"
            >
                {/* Left Side - Text and Buttons */}
                <Grid item xs={12} md={6}>
                    <Typography
                        variant="h4"
                        sx={{
                            fontWeight: 700,
                            fontFamily: 'MyCustomFont',
                            fontSize: { xs: '24px', md: '48px' },
                            textAlign: { xs: 'left', md: 'left' },
                            marginBottom: '16px'
                        }}
                    >
                        Share the Love of Dogs!
                    </Typography>
                    <Typography
                        variant="body1"
                        sx={{
                            fontFamily: 'MyCustomFont1',
                            fontWeight: 600,
                            color: Colors.black,
                            fontSize: { xs: '16px', md: '20px' },
                            textAlign: { xs: 'left', md: 'left' },
                            marginBottom: '24px'
                        }}
                    >
                        Connecting dog-owners<br /> and non-owners to share<br /> dog care and dog therapy
                    </Typography>

                    <Box display="flex" gap={2} flexDirection={{ xs: 'column', sm: 'row', md: 'column', }}
                        // sx={{ ml: 3, mt: 3 }}
                        width={{ xs: '50%', md: '65%' }}>
                        <a href="https://apps.apple.com/us/app/social-paws/id6443444135" target="_blank" rel="noopener noreferrer">
                            <img style={{ width: '65%', height: '10%', marginLeft: 0 }} src={apple} alt="App Store" />
                        </a>
                        <a href="https://play.google.com/store/apps/details?id=com.bytes.socialpaws&pli=1" target="_blank" rel="noopener noreferrer">
                            <img style={{ width: '65%', height: '10%', marginLeft: 0 }} src={google} alt="Google Play" />
                        </a>
                    </Box>

                    <Box
                        width={{ xs: '100%', md: '100%' }}
                        sx={{
                            // position: 'relative',
                            // overflow: 'hidden',
                            // display: 'flex',
                            display: { xs: 'none', md: 'flex' },
                            height: { xs: '60vh', md: '86%' },
                            maxHeight: '90vh',
                        }}
                    >
                        <Box
                            sx={{
                                position: 'absolute',
                                top: 25,
                                left: 140,
                                width: '100%',
                                height: '100%',
                                backgroundImage: `url(${bg})`,
                                backgroundSize: { xs: 'contain', md: 'contain' },
                                backgroundPosition: 'center',
                                backgroundRepeat: 'no-repeat',
                                // opacity: 0.3,                  
                                // zIndex: 3,
                            }}
                        />
                    </Box>
                </Grid>

                <Box sx={{ position: 'relative', overflow: 'visible' }}>

                    <Fab
                        variant="extended"
                        size="medium"
                        color="primary"
                        sx={{
                            position: 'fixed',
                            bottom: 36,
                            right: 20,
                            zIndex: 1000,
                            backgroundColor: '#2BB1E6',
                            textTransform: 'none',
                            display: { xs: 'flex', sm: 'flex' }
                        }}
                    >
                        <Box
                            component="img"
                            src={MessageIcon}
                            alt="Message Icon"
                            sx={{
                                width: 24,
                                height: 24,
                                marginRight: 1,
                            }}
                        />
                        Contact Us
                    </Fab>
                </Box>

            </Grid >
        </Box >
    );
};

export default LoveOfDogs;

