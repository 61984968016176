import React from 'react';
import { Grid, Typography, Box } from '@mui/material';
import Colors from '../../asstes/color';

const Section = ({ title, description, image, reverse }) => (
    <Box sx={{ my: 12, position: 'relative', }}>
        <Typography
            variant="h2"
            sx={{
                position: 'absolute',
                // top: '10%',
                fontFamily: 'MyCustomFont',
                left: '50%',
                transform: reverse ? 'translate(-50%, -50%) rotate(9deg)' : 'translate(-50%, -50%) rotate(-9deg)',
                fontWeight: 700,
                color: Colors.textSecondary,
                fontSize: { xs: '2rem', md: '2.8rem' },
                whiteSpace: 'nowrap',
                textAlign: 'center',
                zIndex: 1,
            }}
        >
            {title}
        </Typography>

        <Grid
            container
            spacing={4}
            alignItems="center"
            direction={reverse ? 'row-reverse' : 'row'}
            sx={{ position: 'relative' }}
        >
            <Grid item xs={12} md={6} sx={{ display: 'flex', justifyContent: 'center' }}>
                {/* Image */}
                <Box
                    component="img"
                    src={image}
                    alt={title}
                    sx={{ width: '70%', height: 'auto', }}
                />
            </Grid>

            <Grid item xs={12} md={6}>
                <Typography variant="body1" sx={{
                    flexGrow: 1,
                    mr: 2, ml: 3,
                    display: { xs: 'flex', md: 'flex' },
                    fontFamily: 'MyCustomFont1',
                    fontWeight: 400,
                    fontSize: { xs: 20, md: 24 },
                    // letterSpacing: '.3rem',
                    color: Colors.textSecondary,
                    textDecoration: 'none',
                }}>
                    {description}
                </Typography>
            </Grid>
        </Grid>
    </Box >
);

export default Section;
