import React from 'react';
import { Box, Grid, Container } from '@mui/material';
import img1 from '../../asstes/images/sponsers_images/img1.png'
import img2 from '../../asstes/images/sponsers_images/img2.png'
import img3 from '../../asstes/images/sponsers_images/img3.png'
import img4 from '../../asstes/images/sponsers_images/img4.png'
import img5 from '../../asstes/images/sponsers_images/img5.png'
import Colors from '../../asstes/color';

const logos = [
    { src: img1, alt: 'Earth Rated' },
    { src: img2, alt: 'CSI Tech Incubator' },
    { src: img3, alt: 'Walkee Paws' },
    { src: img4, alt: 'SuperZoo' },
    { src: img5, alt: 'Brilliant Veterinary Care' },
];

const SponsersView = () => {
    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                mt: 5,mb:4,
                width: '100%',
                backgroundColor: Colors.white,
            }}
        >
            <Container>
                <Box
                    sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        justifyContent: 'center',
                        gap: 2,
                    }}
                >
                    {logos.map((logo, index) => (
                        <Box
                            key={index}
                            component="img"
                            src={logo.src}
                            alt={logo.alt}
                          
                            sx={{
                                objectFit: 'contain',
                                width: {
                                    xs: '60px',  
                                    sm: '80px', 
                                    md: '120px', 
                                    lg: '150px', 
                                },
                                height: 'auto',
                                display: 'block',
                            }}
                        />
                    ))}
                </Box>
            </Container>
        </Box>
    );
};

export default SponsersView;
