import React from 'react';
import { Box, Typography, Card, CardContent, Container } from '@mui/material';
import { styled } from '@mui/system';
import img3 from '../../asstes/images/origin_story/img3.png'
import img4 from '../../asstes/images/origin_story/img4.png'
import img5 from '../../asstes/images/origin_story/img5.png'
import img6 from '../../asstes/images/origin_story/img6.png'
import Colors from '../../asstes/color';


// Styled component for the horizontal scroll container
const ScrollContainer = styled(Box)({
    display: 'flex',
    overflowX: 'auto',
    gap: 100,
    padding: '16px 0',
    '&::-webkit-scrollbar': {
        display: 'none',
    },
    // scrollbarWidth: 'none',
});

// Card component with image
const FeatureCard = ({ title, description, imageUrl }) => (
    <Card sx={{ minWidth: 330, border: 'none', boxShadow: 'none' }} elevation={0}>
        <Typography variant="h5" align="center" gutterBottom sx={{
            fontFamily: 'MyCustomFont',
            // left: '50%',
            fontWeight: 700,
            color: Colors.textSecondary,
            fontSize: { xs: '1.8rem', md: '3rem' },
            whiteSpace: 'nowrap',
            textAlign: 'center',
        }}>
            {title}
        </Typography>

        {/* <Box
            component="img"
            src={imageUrl}
            alt={title}
            sx={{
                width: '80%',
                height: 'auto',
                objectFit: 'contain', ml: '10%'
            }}
        /> */}
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: 240,
                overflow: 'hidden',
            }}
        >
            <Box
                component="img"
                src={imageUrl}
                alt={title}
                sx={{
                    maxWidth: '100%',
                    maxHeight: '100%',
                    objectFit: 'cover',
                }}
            />
        </Box>
        <CardContent>
            <Typography variant="body2" sx={{
                flexGrow: 1,
                mr: 2, ml: 3,
                display: { xs: 'flex', md: 'flex' },
                fontFamily: 'MyCustomFont1',
                fontWeight: 400,
                fontSize: { xs: 18, md: 25 },
                // letterSpacing: '.3rem',
                color: Colors.textSecondary,
                textDecoration: 'none',
            }}>
                {description}
            </Typography>
        </CardContent>
    </Card>
);

// Main horizontal scroll section
const ScrollableView = () => {
    return (
        <Box sx={{ padding: { xs: 1, md: 0 } }}>
            <ScrollContainer>
                <FeatureCard
                    title="Cost"
                    description="Instead of paying someone to watch their dog, Team Paws will be matched with a neighbor who is wanting and willing to love and care for their dog in their own time as a part of the Social Paws community"
                    imageUrl={img3}
                />
                <FeatureCard
                    title="Community"
                    description="We offer community and relationship building, which is not a feature any other dog care app has currently. Through our experience, we encourage socialization and meetups, not only so dog-sharing can be a safe experience, but also so that we can foster a community built around the shared love for dogs"
                    imageUrl={img4}
                />
                <FeatureCard
                    title="Curated"
                    description="Compared to traditional dog sitting and doggy daycare services, which may have uncontrollable variables, Social Paws offers the opportunity to receive more personalized care by way of a social community.
Both Team Social and Team Paws are more in control of their experience by journeying through our tried and true “Snooping, Sniffing, Sharing” process together, building trust over time toward a most rewarding relationship.
Dog owners feel empowered knowing that Team Social is giving their dog active care. And non-owners get to elevate their activities with the companionship of a dog. Learn more about our features and “Snooping, Sniffing, Sharing” process in our FAQs"
                    imageUrl={img5}
                />
                <FeatureCard
                    title="Love"
                    description="At the end of the day, what truly sets Social Paws apart is that this app is fueled by a shared love for dogs. There is no monetary incentive to want to share dogs. We’re bringing people who love spending time with dogs together"
                    imageUrl={img6}
                />
            </ScrollContainer>
        </Box>
    );
};

export default ScrollableView;
