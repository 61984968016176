import React from 'react';
import { Container, Grid, Typography, Box, Button, Card, CardMedia, CardContent } from '@mui/material';
import { useParams, useLocation, Link } from 'react-router-dom';
import NavBar from '../../components/NavBar';
import Footer from '../../components/Footer';
import img3 from '../../asstes/images/blog/img3.png'
import Colors from '../../asstes/color';


const BlogView = () => {
    const location = useLocation();
    const { post } = location.state || {};


    return (
        <Box sx={{ padding: { xs: 1, md: 0 } }}>
            <NavBar screen={"event"} />

            {/* Main Blog Title */}
            <Typography variant="h3" align="center" gutterBottom sx={{
                fontFamily: 'MyCustomFont',
                fontWeight: 700,
                fontSize: { xs: 30, sm: 35, md: 40 },
                color: Colors.textSecondary,
                textDecoration: 'none',
                mt: 1
            }}>
                Blog
            </Typography>

            {/* Blog Post Title */}
            <Typography variant="h5" align="center" gutterBottom sx={{
                fontFamily: 'MyCustomFont1',
                fontWeight: 600,
                fontSize: { xs: 20, sm: 25, md: 30 },
                color: Colors.black,
                textDecoration: 'underline',
                mt: 1
            }}>
                {post.topic}
            </Typography>

            {/* <Box display="flex" justifyContent="center" my={4}>
                <img src={post.image} alt="Blog Post Image"
                    style={{ width: '30%', maxWidth: '100%', height: 'auto' }}
                />
            </Box> */}
            {/* <Box sx={{ display: "flex", justifyContent: "center", my: 4, width: { xs: '90%', sm: '80%', md: '30%' } }}>
                <img
                    src={post.image}
                    alt="Blog Post Image"
                    style={{ width: '100%', height: 'auto', maxWidth: '100%' }}
                />
            </Box> */}
            <Box display="flex" justifyContent="center" my={4}>
                <Box
                    component="img"
                    src={post.image}
                    alt="Blog Post Image"
                    sx={{
                        width: { xs: '80%', sm: '60%', md: '30%' },
                        maxWidth: '100%',
                        height: 'auto',
                    }}
                />
            </Box>
            <Box display="flex" justifyContent="center" my={8} sx={{ ml: 5, mr: 5 }}>
                <div
                    dangerouslySetInnerHTML={{ __html: post.description }}
                    style={{
                        fontFamily: 'MyCustomFont1',
                        fontWeight: 500, color: Colors.black, fontSize: { xs: 15, sm: 20, md: 25 },
                    }}
                ></div>
            </Box>
            <Footer />
        </Box>
    );
};

export default BlogView;