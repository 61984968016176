import React from 'react';
import { Typography, Accordion, AccordionSummary, AccordionDetails, Grid, IconButton, Divider } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FavoriteIcon from '@mui/icons-material/Favorite';
import Box from '@mui/material/Box';
import NavBar from '../../components/NavBar';
import Footer from '../../components/Footer';
import Colors from '../../asstes/color';

function FaqScreen() {
    const faqSections = [
        {
            title: 'Dog Sharing',
            questions: [
                // 'What even is dog sharing?',
                // 'Why dog-share?',
                // 'Who pays for the dog’s needs: ie: dog grooming, food, treats, etc?',
                // 'Do the dogs enjoy dog-sharing?',
            ],
        },
        { title: 'Platform FAQs', questions: [] },
        { title: 'Safety', questions: [] },
        { title: 'Team Paws FAQs', questions: [] },
        { title: 'Team Social FAQs', questions: [] },
    ];

    return (
        <Box sx={{ padding: { xs: 1, md: 0 } }}>
            <NavBar screen={"event"} />
            {/* Header */}
            <Grid container spacing={2} justifyContent="center" alignItems="center">
                <Grid item xs={12} textAlign="center">
                    <Typography variant="h3" color="primary" gutterBottom sx={{
                        fontFamily: 'MyCustomFont',
                        // left: '50%',
                        fontWeight: 700,
                        color: Colors.textSecondary,
                        fontSize: { xs: '1.8rem', md: '3rem' },
                        whiteSpace: 'nowrap',
                        textAlign: 'center',
                        // zIndex: 1,
                    }}>
                        FAQ
                    </Typography>
                </Grid>
            </Grid>

            {/* FAQ Sections */}
            <Box sx={{ padding: 3 }}>
                {faqSections.map((section, index) => (
                    <Box key={index} sx={{ mb: 3 }}>
                        <Accordion>
                            <AccordionSummary
                                expandIcon={<FavoriteIcon color="primary" />}
                                // expandIcon={null}
                                sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                            >
                                <Typography variant="h5" fontWeight="bold">
                                    {section.title}
                                </Typography>
                                {/* <IconButton color="primary" sx={{ ml: 2 }}>
                                    <FavoriteIcon />
                                </IconButton> */}
                            </AccordionSummary>
                            <AccordionDetails>
                                {section.questions.length > 0 ? (
                                    section.questions.map((question, idx) => (
                                        <Grid container key={idx} alignItems="center" spacing={1} sx={{ my: 1 }}>
                                            <Grid item xs={10}>
                                                <Typography color="primary">{question}</Typography>
                                            </Grid>
                                            <Grid item xs={2} textAlign="right">
                                                <IconButton color="primary">
                                                    <FavoriteIcon />
                                                </IconButton>
                                            </Grid>
                                            {idx !== section.questions.length - 1 && <Divider />}
                                        </Grid>
                                    ))
                                ) : (
                                    <Typography variant="body2" color="textSecondary">
                                        No questions available.
                                    </Typography>
                                )}
                            </AccordionDetails>
                        </Accordion>
                    </Box>
                ))}
            </Box>


            <Footer />
        </Box>
    );
}

export default FaqScreen;
