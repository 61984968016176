import listAPI from "../api/api";
import CreateDataContext from "./CreateDataContext";

const BlogReducer = (state, action) => {
    switch (action.type) {
        case "get_all_blogs":
            return { ...state, BlogList: action.payload };
        case "get_all_blogs_status":
            return { ...state, BlogListStatus: action.payload };
        case "clear_get_all_blogs_status":
            return { ...state, BlogListStatus: "" };
        default:
            return state;
    }
};

const config = {
    headers: {
        "Content-Type": "application/json",
    },
};

const getAllBlogs = (dispatch) => async () => {
    try {
        const response = await listAPI.get('blog/getAll');
        dispatch({ type: "get_all_blogs", payload: response.data });
        dispatch({ type: "get_all_blogs_status", payload: response.status, });

    } catch (e) {
        console.log("errrr",e);
        dispatch({ type: "get_all_blogs_status", payload: 400 });
    }
};

const clearGetAllBlogsStatus = (dispatch) => () => {
    dispatch({ type: "clear_get_all_blogs_status" });
};


export const { Provider, Context } = CreateDataContext(
    BlogReducer,
    {
        getAllBlogs, clearGetAllBlogsStatus,
    },
    {
        BlogList: [],
        BlogListStatus: "",
    }
);
