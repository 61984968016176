import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import SP_Pattern from '../../asstes/images/SP_Pattern.png' 
import Colors from '../../asstes/color';

const useStyles = makeStyles((theme) => ({
    background: {
        backgroundImage: `url(${SP_Pattern})`, // Use the imported image here
        backgroundSize: 'contain',
        // backgroundAttachment: 'fixed',
        width: '100%',
        height: '30vh',//100
        display: 'flex',
        overflow: 'hidden',
        [theme.breakpoints.down('sm')]: { // Adjust for mobile screens
            height: '15vh',  // Increase or decrease this value for mobile screen height
        },
    },
    scrollingText: {
        display: 'inline-block',
        whiteSpace: 'nowrap',
        opacity: 1,
        fontSize: '2rem',
        color: '#000',
        transition: 'opacity 1s ease-in-out',
    },
    hiddenText: {
        opacity: 0,
    },
}));

function WhatsSP() {
    const classes = useStyles();

    return (
        <Box className={classes.background}>
            <Box >
                <Typography
                    sx={{
                        flexGrow: 1,
                        mr: 2,
                        ml: { xs: 2, md: 6 },
                        display: { xs: 'flex', md: 'flex' },
                        fontFamily: 'MyCustomFont1',
                        fontWeight: 700,
                        fontSize: { xs: '24px', md: '40px' },
                        // letterSpacing: '.3rem',
                        color: Colors.textSecondary,
                        textDecoration: 'none', mt: 4
                    }}
                >
                    What Is Social Paws?
                </Typography>
            </Box>
        </Box>
    );
}

export default WhatsSP;
