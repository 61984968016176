import React from 'react';
import { Container, Box, Typography } from '@mui/material';
import NavBar from '../../components/NavBar';
import Footer from '../../components/Footer';
import Section from './Section';
import img1 from '../../asstes/images/how_itswork/img1.png';
import img2 from '../../asstes/images/how_itswork/img2.png';
import img3 from '../../asstes/images/how_itswork/img3.png';
import Colors from '../../asstes/color';

function HowItsWork() {
    return (
        <Box sx={{ padding: { xs: 1, md: 0 } }}>
            <NavBar screen={"event"} />
            <Box sx={{ mt: 3,mb:4, textAlign: 'center' }}>
                <Typography
                    variant="h2"
                    sx={{
                        fontFamily: 'MyCustomFont',
                        // left: '50%',
                        fontWeight: 700,
                        color: Colors.textSecondary,
                        fontSize: { xs: '1.8rem', md: '3rem' },
                        whiteSpace: 'nowrap',
                        textAlign: 'center',
                        // zIndex: 1,
                    }}
                >
                    The Social Paws Way!
                </Typography>
            </Box>

            <Section
                title="Snooping"
                description="With just a username and password, you can snoop around the neighborhood and check out all the dogs and humans in our browsing experience or at the dog park, aka our feed. Snoop as much as you want, until you feel inspired by someone’s profile or discussions to move into Sniffing"
                image={img1}
            />
            <Section
                title="Sniffing"
                description="Once you want to express your interest to a dog by “tossing a treat” and to a human by “wagging your tongue,” you’ll be prompted to complete your profile with our profile quiz, photo uploads, and identity verification steps. Start chatting with a dog’s owner or to a dog-loving human by just clicking the message icon. If you want to move into our next phase, “Sharing,” schedule a dog walk to Sniff in real life. During your in-person dog-walk, vet one another for personality, trust, and communication. Keep hanging out until you confident enough to move into Sharing"
                image={img2}
                reverse
            />
            <Section
                title="Sharing"
                description="Once you’re ready to start dog-sharing, Team Paws will be prompted to complete a vet form and both teams will confirm that they are ready to “hand the leash over.” This means that Team Social can spend time with the dog on their own in the form of walks, playdates, and sleepovers. Either Team Paws or Team Social can request these shares and our app concierge will handle your communication, scheduling, and logistics with ease. Happy dog-sharing the Social Paws way"
                image={img3}
            />
            <Footer />
        </Box>
    );
}

export default HowItsWork;
