import React, { useState } from 'react';
import { Container, Grid, Typography, Button, Box, AppBar, Toolbar, Link } from '@mui/material';
import NavBar from '../../components/NavBar';
import Footer from '../../components/Footer';
import img1 from '../../asstes/images/meet_team/img1.png'
import img2 from '../../asstes/images/meet_team/img2.png'
import img3 from '../../asstes/images/meet_team/img3.png'
import img4 from '../../asstes/images/meet_team/img4.png'
import img5 from '../../asstes/images/meet_team/img5.png'
import img6 from '../../asstes/images/meet_team/img6.png'
import Colors from '../../asstes/color';

function MeetTeam() {
    const [activeMember, setActiveMember] = useState(null);

    const teamMembers = [
        { image: img1, name: 'Alice Smith', position: 'CEO' },
        { image: img2, name: 'Lisa', position: 'Creative Director' },
        { image: img3, name: 'Armando', position: 'Software Developer' },
        { image: img4, name: 'Bob Johnson', position: 'CTO' },
        { image: img5, name: 'Carol Williams', position: 'COO' },
        { image: img6, name: 'Carol Williams', position: 'COO' },

    ];

    const handleImageClick = (index) => {
        setActiveMember(index);

        // Automatically revert back to image after 3 seconds
        setTimeout(() => {
            setActiveMember(null);
        }, 3000);
    };

    return (
        <Box sx={{ padding: { xs: 1, md: 0 } }}>
            <NavBar screen={"event"} />

            <Typography variant="h3" align="center" color="primary" gutterBottom sx={{
                fontFamily: 'MyCustomFont',
                fontWeight: 700,
                fontSize: { xs: 30, sm: 35, md: 40 },
                color: Colors.textSecondary,
                textDecoration: 'none',
                mt: 1
            }}>
                Meet the Team
            </Typography>

            <Grid container spacing={0} sx={{ mt: 4 }}>
                {teamMembers.map((member, index) => (
                    <Grid item xs={12} sm={6} md={4} key={index}>
                        <Box
                            onClick={() => handleImageClick(index)}
                            sx={{
                                cursor: 'pointer',
                                width: '100%',
                                height: '500px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                // borderRadius: '8px',
                                overflow: 'hidden',
                                backgroundColor: '#E6F2FA',
                                position: 'relative',
                            }}
                        >

                            {activeMember === index ? (
                                <Box sx={{ textAlign: 'center' }}>
                                    <Typography variant="h5" sx={{
                                        fontFamily: 'MyCustomFont',
                                        fontWeight: 400,
                                        fontSize: { xs: 20, sm: 25, md: 30 },
                                        color: Colors.textSecondary,
                                        textDecoration: 'none',
                                    }}>
                                        {member.name}
                                    </Typography>
                                    <Typography variant="subtitle1" sx={{
                                        fontFamily: 'MyCustomFont1',
                                        fontWeight: 300,
                                        fontSize: { xs: 18, sm: 20, md: 25 },
                                        color: Colors.textSecondary,
                                        textDecoration: 'none',
                                    }}>
                                        {member.position}
                                    </Typography>
                                </Box>
                            ) : (
                                <Box
                                    component="img"
                                    src={member.image}
                                    alt={member.name}
                                    sx={{ width: '100%', height: '100%', objectFit: 'cover' }}
                                />
                            )}
                        </Box>
                    </Grid>
                ))}
            </Grid>

            <Footer />
        </Box>
    );
}

export default MeetTeam;
