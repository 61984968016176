import React, { useState, useContext, useEffect } from 'react';
import { Container, Grid, Typography, Box, Button, Card, CardMedia, CardContent, } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Context as BlogContext } from "../../context/BlogContext";
import NavBar from '../../components/NavBar';
import Footer from '../../components/Footer';
import Colors from '../../asstes/color';
import img1 from '../../asstes/images/blog/img1.png'
import img2 from '../../asstes/images/blog/img2.png'

const BlogScreen = () => {
    const navigate = useNavigate();
    const { state: { BlogList, BlogListStatus }, getAllBlogs, clearGetAllBlogsStatus, } = useContext(BlogContext);

    useEffect(() => {
        getAllBlogs()
    }, []);

    const handleNavigate = (post) => {
        navigate(`/blog/${post._id}`, { state: { post } });
    };


    const formatDate = (isoDate) => {
        return new Date(isoDate).toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
        });
    };

    const BlogDate = ({ dateString }) => {
        const formattedDate = formatDate(dateString);

        return <Typography variant="body2" sx={{
            fontFamily: 'MyCustomFont1',
            fontWeight: 300,
            // fontSize: { xs: 20, sm: 25, md: 28 },
            color: Colors.textSecondary,
            textDecoration: 'none',

        }}>{formattedDate}</Typography>;
    };


    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
            <NavBar screen={"event"} />
            <Box textAlign="center" my={4}>
                <Typography variant="h4" component="h1" sx={{
                    fontFamily: 'MyCustomFont',
                    fontWeight: 700,
                    fontSize: { xs: 30, sm: 35, md: 40 },
                    color: Colors.textSecondary,
                    textDecoration: 'none',
                    mt: 1
                }}>
                    Blog
                </Typography>
            </Box>
            <Container maxWidth="lg" sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: BlogList?.data?.length ? 'flex-start' : 'center', alignItems: 'center' }}>
                {BlogList?.data?.length > 0 ? (
                    <Grid container direction="column" spacing={1} sx={{ mb: 10 }}>
                        {BlogList?.data?.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
                            .map((post, index) => (
                                <Grid item key={index}>
                                    <Card sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, alignItems: 'center', border: 'none', boxShadow: 'none', cursor: 'pointer', }} elevation={0}
                                        onClick={() => handleNavigate(post)}>
                                        <CardMedia
                                            component="img"
                                            sx={{
                                                width: { xs: '100%', md: 270 },
                                                height: { xs: 150, md: 200 },
                                                // borderRadius: 2,
                                                objectFit: 'cover',
                                                // cursor: 'pointer',
                                            }}
                                            image={post.image}
                                            alt={post.topic}
                                        // onClick={() => handleNavigate(post.path)}
                                        // onClick={() => handleNavigate(`/blog/${post.id}`)}
                                        />
                                        <CardContent sx={{ flex: 1, paddingLeft: { md: 2 }, paddingTop: { xs: 2, md: 0 }, }}
                                        // onClick={() => handleNavigate(post.path)}
                                        // onClick={() => handleNavigate(`/blog/${post.id}`)}
                                        >
                                            <Typography variant="h6" component="h2" gutterBottom sx={{
                                                fontFamily: 'MyCustomFont1',
                                                fontWeight: 600,
                                                fontSize: { xs: 20, sm: 25, md: 28 },
                                                color: Colors.black,
                                                textDecoration: 'none',
                                            }}>
                                                {post.topic}
                                            </Typography>

                                            <BlogDate dateString={post.createdAt} />
                                            {/* </Typography> */}
                                        </CardContent>
                                    </Card>
                                </Grid>
                            ))}
                    </Grid>) : (
                    <Box textAlign="center" mt={4}>
                        <Typography variant="h6" color="textSecondary">
                            No blog posts available.
                        </Typography>
                    </Box >
                )}
            </Container >
            <Footer />
        </Box>
    );
};

export default BlogScreen; 
